/*----------------------------------------------------------------------------*
	$VIDEO
*----------------------------------------------------------------------------*/


/* ^.o-video
-----------------------------------------------------------------------------*/

.o-video
	position: relative
	margin-top: 64px
	margin-bottom: 53px

	figure
		position: relative
		border: 5px solid #faed99
		z-index: 10

		img
			display: block

.o-video__icon-play
	position: absolute
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100%
	top: 0
	left: 0
	z-index: 20

	i
		font-size: 6rem
		color: #fff

.o-video__bg-ornamentosVideo
	position: absolute
	top: -63px
	right: -30px
	bottom: -53px
	left: -16px
	background: url($img-path + 'bg-ornamentosvideos.png') center center no-repeat
	background-size: contain

	+max('s')
		left: -15px
		right: -15px

/* ^Fin - .o-video
-----------------------------------------------------------------------------*/
