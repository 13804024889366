/*----------------------------------------------------------------------------*
	$REPRODUCTOR
*----------------------------------------------------------------------------*/


/* ^Reproductor video
-----------------------------------------------------------------------------*/

.o-player-wrap
	width: 100%
	position: relative
	padding: 56.25% 0 0 0

	iframe
		position: absolute
		width: 100% !important
		height: 100%
		top:0
		left: 0

/* ^Fin - Reproductor video
-----------------------------------------------------------------------------*/
