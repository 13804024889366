/*----------------------------------------------------------------------------*
	$BTN
*----------------------------------------------------------------------------*/


/* ^.o-btn
-----------------------------------------------------------------------------*/

// Boton por defecto
.o-btn
	btn()
	display: inline-flex
	align-items: center
	height: 40px
	padding: 0 25px
	border-radius: 5px
	background: #44a7d4
	font-size: 1.7rem
	color: #fff
	transition: all .3s ease

	&:hover
		background: darken(#44a7d4,10)
		color: #fff

	.fa
		margin-right: 10px



.o-btn-azul2
	@extend .o-btn
	background: #3bb5e3

	&:hover
		background: darken(#3bb5e3,8)


.o-btn-verde
	@extend .o-btn
	background: $base-color

	&:hover
		background: darken($base-color,8)


.o-btn-verde-2
	@extend .o-btn
	background: $base6-color

	&:hover
		background: darken($base6-color,8)

/* ^Fin - .o-btn
-----------------------------------------------------------------------------*/
