/*----------------------------------------------------------------------------*
	$HEADER
*----------------------------------------------------------------------------*/

html
	font-size: $base-font-size

body
	padding-top: 133px
	font-family: $base-font-family
	font-size: $text-size.normal
	font-weight: $base-font-weight
	line-height: 1.2
	color: $base-text-color
	font-smoothing()
	overflow-x: hidden




/* ^Banner hero
-----------------------------------------------------------------------------*/

.banner-hero
	position: relative
	height: 85vh
	background: $base-color
	overflow: hidden

	+max('xl')
		height: 500px

	+max('m')
		height: 300px

	+max('s')
		height: 250px

.banner-home
	width: 100% !important
	height: 85vh !important

	+max('xl')
		height: 500px !important

	+max('m')
		height: 300px !important

	+max('s')
		height: 250px !important

	&__play
		position: absolute
		width: 100px
		height: @width
		top: 50%
		left: 50%
		margin-top: -(@width / 2)
		margin-left: -(@width / 2)
		background: url($img-path + 'play.png') no-repeat
		background-size: contain
		z-index: 20

		+max('m')
			width: 50px
			height: @width
			margin-top: -(@width / 2)
			margin-left: -(@width / 2)


.banner-hero--v2
	@extend .banner-hero
	height: 60vh

.banner-hero--v3
	@extend .banner-hero
	height: 60vh

	&:before
		position: absolute
		content: ''
		width: 100%
		height: 44px
		bottom: 0
		background: url($img-path + 'triangulo-section.svg') center bottom no-repeat
		z-index: 99

		+max('l')
			display: none

.banner-home--v2
	@extend .banner-home
	height: 60vh !important

.banner-home__text-content
	position: absolute
	width: 100%
	height: 100%
	top: 0
	left: 0

	.banner-home__text
		display: flex
		justify-content: center
		align-items: flex-end
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		padding-bottom: 100px
		padding-left: 30px
		padding-right: 30px
		background: linear-gradient(-180deg, rgba(39,42,43,0.00) 49%, rgba(40,42,44,0.85) 98%)

		+max('m')
			padding-bottom: 70px
			background: linear-gradient(-180deg, rgba(39,42,43,0.00) 29%, rgba(40,42,44,0.85) 98%)


		p
			max-width: 800px
			text-align: center
			font-family: $base-font-family
			font-size: 2.5rem
			color: #fff
			text-shadow: 1px 2px 0 rgba(#000, .5)

			+max('l')
				font-size: 2rem

			+max('m')
				font-size: 1.7rem

			b,
			strong
				display: block
				padding-top: 5px
				font-family: $base-font-family
				font-size: 4rem
				color: #fff

				+max('l')
					font-size: 3rem

				+max('m')
					font-size: 2rem

			.banner-home__text-title

				+max('m')
					font-size: 3rem


/* ^Fin - Banner hero
-----------------------------------------------------------------------------*/
