/*----------------------------------------------------------------------------*
	$C-HEADER
*----------------------------------------------------------------------------*/


/* ^.c-header
-----------------------------------------------------------------------------*/

.c-header
	width: 100%
	margin-bottom: 50px
	text-align: center

.c-header__titulo
	margin-bottom: 5px
	color: $base-color

.c-header__descripcion
	font-size: 1.9rem

/* ^Fin - .c-header
-----------------------------------------------------------------------------*/





/* ^c-header-1
-----------------------------------------------------------------------------*/

.c-header-1
	position: relative
	padding: 45px 0 45px 55px
	margin-left: -30px
	background-color: #E5E5E5
	background-position: center center
	background-size: cover

	&:before
		content: ''
		position: absolute
		z-index: 1
		top: 0
		left: 0
		width: 100%
		height: 100%

	+min('xl')
		width: calc(100% + 30px + (100vw - 970px) / 2)

	+min('hd')
		width: calc(100% + 30px + (100vw - 1134px) / 2)


	+at('xl')
		margin-left: calc(((100vw - 750px) / 2) * -1)
		margin-right: calc(((100vw - 750px) / 2) * -1)

	+max('xl')
		padding: 45px 20px
		text-align: center

	+max('l')
		margin-left: -20px
		margin-right: -20px





.c-header-1--verde
	@extends .c-header-1

	&:before
		background-color: rgba(darken($base-color, 10), .85)


.c-header-1--azul
	@extends .c-header-1

	&:before
		background-color: rgba(darken($base2-color, 13), .85)


.c-header-1--naranja
	@extends .c-header-1

	&:before
		background-color: rgba(darken($base5-color, 16), .85)


.c-header-1--verde-2
	@extends .c-header-1

	&:before
		background-color: rgba(darken($base6-color, 17), .85)


.c-header-1--morado
	@extends .c-header-1

	&:before
		background-color: rgba(darken($base7-color, 15), .85)


.c-header-1--ocre
	@extends .c-header-1

	&:before
		background-color: rgba(darken($base8-color, 20), .85)


.c-header-1__heading
	position: relative
	z-index: 2
	color: #fff


/* ^Fin - c-header-1
-----------------------------------------------------------------------------*/






/* ^.c-header-2
-----------------------------------------------------------------------------*/

.c-header-2
	position: relative
	padding: 60px 20px
	background-color: #E5E5E5
	background-position: center center
	background-size: cover
	text-align: center

	&:before
		content: ''
		position: absolute
		z-index: 1
		top: 0
		left: 0
		width: 100%
		height: 100%


.c-header-2--verde
	@extends .c-header-2

	&:before
		background-color: rgba(darken($base-color, 10), .85)


.c-header-2--azul
	@extends .c-header-2

	&:before
		background-color: rgba(darken($base2-color, 13), .85)


.c-header-2--naranja
	@extends .c-header-2

	&:before
		background-color: rgba(darken($base5-color, 16), .85)


.c-header-2--verde-2
	@extends .c-header-2

	&:before
		background-color: rgba(darken($base6-color, 17), .85)


.c-header-2--morado
	@extends .c-header-2

	&:before
		background-color: rgba(darken($base7-color, 15), .85)


.c-header-2--ocre
	@extends .c-header-2

	&:before
		background-color: rgba(darken($base8-color, 20), .85)


.c-header-2__heading
	position: relative
	z-index: 2
	max-width: 600px
	margin: 0 auto
	color: #fff

/* ^Fin - .c-header-2
-----------------------------------------------------------------------------*/
