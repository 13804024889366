/*----------------------------------------------------------------------------*
	$C-LIST
*----------------------------------------------------------------------------*/


/* ^.c-list
-----------------------------------------------------------------------------*/

.c-list
	grid()


.c-list__item
	+max('l')
		margin-bottom: 25px

.c-list__fecha
	margin-bottom: 15px
	font-size: 1.8rem
	color: $base5-color

	i
		margin-right: 10px


.c-list__img
	display: block
	margin-bottom: 20px
	border: 3px solid #faed99


.c-list__heading
	margin-bottom: 15px
	font-size: 2rem


.c-list__p
	line-height: 1.3

/* ^Fin - .c-list
-----------------------------------------------------------------------------*/
