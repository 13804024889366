/*----------------------------------------------------------------------------*
	$BLOCKS-CMS
*----------------------------------------------------------------------------*/

/* ^Titulos
-----------------------------------------------------------------------------*/

h1, .h1,
h2, .h2,
h3, .h3,
h4,	.h4,
h5, .h5,
h6, .h6
	margin-bottom: 15px
	color: #24282D

/* ^Fin - Titulos
-----------------------------------------------------------------------------*/




/* ^Parrafos
-----------------------------------------------------------------------------*/

p
	line-height: 1.4

	[class*="btn"]
		margin-right: 12px


.justify
	text-align: justify

/* ^Fin - Parrafos
-----------------------------------------------------------------------------*/




/* ^Imagenes
-----------------------------------------------------------------------------*/

.img-iz
	display: block
	margin-bottom: 20px

	+min(601px)
		float: left
		width: calc(50% - 30px)
		margin-right: 30px
		margin-bottom: 10px

.img-der
	display: block
	margin-bottom: 20px

	+min(601px)
		float: right
		width: calc(50% - 30px)
		margin-left: 30px
		margin-bottom: 10px

.img-full
	display: block
	width: 100%
	margin-bottom: 20px


/* ^Fin - Imagenes
-----------------------------------------------------------------------------*/




/* ^Bloques
-----------------------------------------------------------------------------*/

.block-content
	clearfix()
	margin-bottom: 30px

	&:last-child
		margin-bottom: 0

.block-content--center
	@extend .block-content

	> *
		max-width: 550px
		margin-left: auto
		margin-right: auto
		text-align: center

.block-columnas
	margin-bottom: 30px

	&:last-child
		margin-bottom: 0

	+min(601px)
		grid()

	+max('l')
		padding-left: 20px
		padding-right: 20px

.col-1-2
	+min(601px)
		col(1,2)

	+max(600px)
		margin-bottom: 20px

.col-1-3
	+min(601px)
		col(1,3)

	+max(600px)
		margin-bottom: 20px


/* ^Fin - Bloques
-----------------------------------------------------------------------------*/





/* ^General
-----------------------------------------------------------------------------*/

.espacio
	width: 100%
	height: 50px

.center
	text-align: center


.media-content
	margin-bottom: 20px

	img
		display: block

.media-content--radius
	@extend .media-content

	img
		max-width: 200px
		border-radius: 50%
		margin-left: auto
		margin-right: auto

.separador-cms
	width: 100%
	height: 25px
	margin-bottom: @height
	border-bottom: 1px solid #e0e6ec

.titulo-notifica
	color: #fff

.area-cms
	width: 100%


.block-content__btns
	text-align: center

	[class*="o-btn"]
		margin: 0 5px

/* ^Fin - General
-----------------------------------------------------------------------------*/





/* ^Listas
-----------------------------------------------------------------------------*/

.block-numberItem
	display: flex

	.number
		display: flex
		justify-content: center
		align-items: center
		margin-right: 10px
		width: 40px
		height: @width
		border-radius: 50%
		background: $base-color
		font-size: 2rem
		color: #fff
		font-weight: $bold

	.icon-section
		display: flex
		justify-content: center
		align-items: center
		width: 40px
		height: @width
		margin-right: 10px
		font-size: 3.3rem
		color: $base-color


.block-numberItem__box
	padding-top: 5px


.block-numberItem__title--v2
	color: $base-color


.block-columnas,
.block-numberItem,
.block-content
	ul
		margin-bottom: 25px

		li
			margin-bottom: 6px

			&.icon
				display: flex

				.fa
					padding-top: 2px
					margin-right: 10px


/* ^Fin - Listas
-----------------------------------------------------------------------------*/






/* ^block-text-center
-----------------------------------------------------------------------------*/

.block-text-center
	grid()
	padding: 65px 0

	+max('l')
		padding-left: 20px
		padding-right: 20px

	+max('m')
		padding-top: 50px
		padding-bottom: 50px

	.g-col
		margin: 0 auto

		+min('xl')
			col(8,12)


	p
		text-align: justify
		font-size: 1.7rem
		line-height: 2.6rem

	strong
		color: #586169


.block-text-center--quienes-somos
	@extend .block-text-center
	padding-top: 0

	+max('l')
		padding-top: 0

.block-text-center--2
	@extend .block-text-center

	p
		text-align: center
		font-size: 2rem
		line-height: 3.2rem

.block-text-center--3
	@extend .block-text-center
	padding: 55px 0

	p
		text-align: center
		font-size: 1.8rem
		line-height: 3rem

.block-text-center--4
	@extend .block-text-center
	padding: 50px 0 30px 0

	+max('m')
		padding-top: 40px

.separator-book
	position: relative
	border-bottom: 2px solid #F0F2F3

	&:before
		position: absolute
		display: flex
		justify-content: center
		align-items: center
		width: 40px
		height: @width
		bottom: -20px
		left: 50%
		margin-left: -20px
		background: #fff
		color: #FFAF3F

/* ^Fin - block-text-center
-----------------------------------------------------------------------------*/





/* ^block-2col-gray
-----------------------------------------------------------------------------*/



.block-2col-gray
	position: relative
	width: 100%
	padding: 60px 0


	+max('l')
		padding-top: 0
		padding-bottom: 0

	.bg
		position: absolute
		width: 100vw
		height: 100%
		top: 0
		left: calc((100% - 100vw) / 2)

		+min('l')
			background: #F5F5F5 url($img-path + 'bg-block-2col-gray.svg') center repeat-y
			background-size: cover

		+max('l')
			display: none

	.g-container
		position: relative
		z-index: 100


	&:before
		content: ''
		position: absolute
		width: 0
		height: 0
		top: 0
		left: 50%
		margin-left: -20px
		border-top: 20px solid #fff
		border-left: 20px solid transparent
		border-right: 20px solid transparent
		z-index: 10

		+max('l')
			display: none

	.g-col
		+min('l')
			col(1,2, $g:75px)

		+max('l')
			padding-top: 50px
			padding-bottom: 50px
			padding-left: 20px
			padding-right: 20px

			&:first-child
				background: #F5F5F5

			&:last-child
				margin-bottom: 0


	h2
		margin-bottom: 35px
		color: #4183D7



	p
		text-align: justify
		font-size: 1.7rem
		line-height: 2.5rem


/* ^Fin - block-2col-gray
-----------------------------------------------------------------------------*/
