/*----------------------------------------------------------------------------*
	$NAV
*----------------------------------------------------------------------------*/


/* ^.c-nav-main
-----------------------------------------------------------------------------*/

// ul
.c-nav-main
	display: flex
	margin-left: auto

	+max('xl')
		display: none

	> li
		position: relative
		z-index: 100

		&:hover
			> a
				background: #f0f6fa
				border-bottom-color: #e4eaee

			> ul
				display: block

		> a
			display: flex
			flex-direction: column
			//justify-content: center
			align-items: center
			max-width: 130px
			height: 130px
			padding-top: 27px
			padding-left: 20px
			padding-right: @padding-left
			border-bottom-width: 4px
			border-bottom-style: solid
			border-bottom-color: transparent
			text-align: center
			transition: all .3s ease

			i
				margin-bottom: 12px
				font-size: 2.5rem
				transition: all .3s ease

			span
				font-size: 1.7rem
				color: #7a848c
				transition: all .3s ease


			&.active
				i,
				span
					color: #fff !important


		// Estilo para cada item personalizado
		&.c-nav-main__verde
			> a
				i
					color: $base3-color

				&.active
					background: $base3-color
					border-bottom-color: darken(@background, 10)

			// submenu
			> ul
				background: rgba(darken($base3-color,10),.9)

				> li
					> a
						background: rgba($base3-color, .4)

						&:hover
							background: rgba($base3-color, .7)

						&.active
							background: $base3-color


		&.c-nav-main__azul
			> a
				i
					color: $base4-color

				&.active
					background: $base4-color
					border-bottom-color: darken(@background, 10)

			// submenu
			> ul
				background: rgba(darken($base4-color,10),.9)

				> li
					> a
						background: rgba($base4-color, .4)

						&:hover
							background: rgba($base4-color, .7)

						&.active
							background: $base4-color

		&.c-nav-main__naranja
			> a
				i
					color: $base5-color

				&.active
					background: $base5-color
					border-bottom-color: darken(@background, 10)

			// submenu
			> ul
				background: rgba(darken($base5-color,10),.9)

				> li
					> a
						background: rgba($base5-color, .4)

						&:hover
							background: rgba($base5-color, .7)

						&.active
							background: $base5-color

		&.c-nav-main__verde2
			> a
				i
					color: $base6-color

				&.active
					background: $base6-color
					border-bottom-color: darken(@background, 10)

			// submenu
			> ul
				background: rgba(darken($base6-color,10),.9)

				> li
					> a
						background: rgba($base6-color, .4)

						&:hover
							background: rgba($base6-color, .7)

						&.active
							background: $base6-color


		&.c-nav-main__morado
			> a
				i
					color: $base7-color

				&.active
					background: $base7-color
					border-bottom-color: darken(@background, 10)

			// submenu
			> ul
				background: rgba(darken($base7-color,10),.9)

				> li
					> a
						background: rgba($base7-color, .4)

						&:hover
							background: rgba($base7-color, .7)

						&.active
							background: $base7-color

		&.c-nav-main__ocre
			> a
				i
					color: $base8-color

				&.active
					background: $base8-color
					border-bottom-color: darken(@background, 10)

			// submenu
			> ul
				background: rgba(darken($base8-color,10),.9)

				> li
					> a
						background: rgba($base8-color, .4)

						&:hover
							background: rgba($base8-color, .7)

						&.active
							background: $base8-color



		// Submenu

		> ul
			display: none
			position: absolute
			padding: 8px

			> li
				width: 240px
				margin-bottom: 2px

				&:last-child
					margin-bottom: 0

				> a
					display: block
					padding: 10px
					font-size: 1.6rem
					color: #fff

/* ^Fin - .c-nav-main
-----------------------------------------------------------------------------*/



/* ^.c-nav-hamburguesa
-----------------------------------------------------------------------------*/

// a
.c-nav-hamburguesa
	display: flex
	align-items: center
	margin-left: auto
	font-size: 2rem
	color: #29bb90

	&:hover
		color: #29bb90

	+min('xl')
		display: none

	i
		margin-right: 10px
		font-size: 2.5rem


// Estilos para slicknav.js
.slicknav_btn
	display: none

/* ^Fin - .c-nav-hamburguesa
-----------------------------------------------------------------------------*/






/* ^#menuMovil
-----------------------------------------------------------------------------*/

#menuMovil
	width: 100%

	+min('xl')
		display: none

	ul
		> li
			i
				width: 30px

			&.c-nav-main__verde
				i
					color: $base3-color

			&.c-nav-main__azul
				i
					color: $base4-color

			&.c-nav-main__naranja
				i
					color: $base5-color

			&.c-nav-main__verde2
				i
					color: $base6-color

			&.c-nav-main__morado
				i
					color: $base7-color

			&.c-nav-main__ocre
				i
					color: $base8-color



			> a
				font-size 16px
				border-bottom 1px dashed #ccc
				padding 10px 20px
				display block
				//background #f0f5f8
				color #7a848c



				&:hover
					text-decoration none !important
					background #dcdee0


				&.active
					border-bottom none
					background #f55656
					color #fff

				> a
					text-decoration none !important
					color: #7a848c

			&.slicknav_open
				> a
					background #dcdee0

					span
						color: darken(#7a848c,10) !important


				> ul > li > a
					background #f0f3f5
					padding-left: 50px

					&:hover
						background #D3D3D3

					&.active
						border-bottom none
						background #f55656
						color #fff

				> ul > li >  ul > li > a
					padding-left: 50px


			&.slicknav_parent
				> a:after
					content "\f054"
					display inline-block
					font normal normal normal 14px/1 FontAwesome
					font-size 1.3rem
					text-rendering auto
					-webkit-font-smoothing antialiased
					-moz-osx-font-smoothing grayscale
					transform translate(0, 0)
					margin-left: 10px
					color: darken(#7a848c,10)



			&.slicknav_parent.slicknav_open
				> a:after
					content "\f078"
					display inline-block
					font normal normal normal 14px/1 FontAwesome
					font-size 1.3rem
					text-rendering auto
					-webkit-font-smoothing antialiased
					-moz-osx-font-smoothing grayscale
					transform translate(0, 0)
					margin-right 10px
					color: darken(#7a848c,10)


			> ul
				> li
					> a
						padding-right 70px
						font-size 15px
						color #7a848c


/* ^Fin - #menuMovil
-----------------------------------------------------------------------------*/





/* ^.c-nav-social
-----------------------------------------------------------------------------*/

// ul
.c-nav-social
	grid()
	margin-bottom: 40px

	li
		margin: 0 1px
		a
			display: flex
			justify-content: center
			align-items: center
			width: 40px
			height: @width
			background: rgba(#fff,.15)
			color: #fff

			&:hover
				background: rgba(#fff,.3)

			span
				display: none

/* ^Fin - .c-nav-social
-----------------------------------------------------------------------------*/





/* ^.c-nav-lateral
-----------------------------------------------------------------------------*/

// ul
.c-nav-lateral
	position: relative

	&:before
		content: ''
		position: absolute
		top: 100%
		width: 100%
		height: 54px
		background-repeat: no-repeat
		background-size: 100% 100%

	li
		position: relative
		border-bottom: 1px solid rgba(#000, .12)

		&:last-child
			border-bottom: none

		a
			display: block
			padding: 12px 20px
			color: #fff
			font-size: 1.7rem
			transition: all .2s ease

			&.active
				width: calc(100% + 24px)
				border-radius: 0 100px 100px 0
				font-weight: 700


.c-nav-lateral--verde
	@extends .c-nav-lateral
	background: $base-color

	&:before
		background-image: url($img-path + 'ornament-nav-lateral.svg')

	li
		a
			&:hover
				background: darken($base-color, 4)

			&.active
				background: darken($base-color, 18)


.c-nav-lateral--azul
	@extends .c-nav-lateral
	background: $base2-color

	&:before
		background-image: url($img-path + 'ornament-nav-lateral-azul.svg')

	li
		a
			&:hover
				background: darken($base2-color, 6)

			&.active
				background: darken($base2-color, 20)


.c-nav-lateral--naranja
	@extends .c-nav-lateral
	background: $base5-color

	&:before
		background-image: url($img-path + 'ornament-nav-lateral-naranja.svg')

	li
		a
			&:hover
				background: darken($base5-color, 9)

			&.active
				background: darken($base5-color, 22)



.c-nav-lateral--verde-2
	@extends .c-nav-lateral
	background: darken($base6-color, 7)

	&:before
		background-image: url($img-path + 'ornament-nav-lateral-verde2.svg')

	li
		a
			&:hover
				background: darken($base6-color, 11)

			&.active
				background: darken($base6-color, 23)



.c-nav-lateral--morado
	@extends .c-nav-lateral
	background: $base7-color

	&:before
		background-image: url($img-path + 'ornament-nav-lateral-morado.svg')

	li
		a
			&:hover
				background: darken($base7-color, 5)

			&.active
				background: darken($base7-color, 20)


.c-nav-lateral--ocre
	@extends .c-nav-lateral
	background: darken($base8-color, 3)

	&:before
		background-image: url($img-path + 'ornament-nav-lateral-ocre.svg')

	li
		a
			&:hover
				background: darken($base8-color, 10)

			&.active
				background: darken($base8-color, 27)

/* ^Fin - .c-nav-lateral
-----------------------------------------------------------------------------*/
