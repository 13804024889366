/*----------------------------------------------------------------------------*
	$NOTICIAS
*----------------------------------------------------------------------------*/

.noticia-detalle
	padding-top: 50px
	padding-bottom: 130px
	background: #f5f8f8 url($img-path + 'bg-children.jpg') center center

.sala-prensa
	position: relative
	min-height: 400px
	padding-top: 50px
	padding-bottom: 130px
	background: #f5f8f8 url($img-path + 'bg-children.jpg') center center

	+min('l')

		&:before
			position: absolute
			content: ''
			width: 2px
			height: 100%
			top: 0
			left: 50%
			margin-left: -1px
			background: $base-color


.sala-prensa__box
	display: block



.sala-prensa__item
	position: relative
	width: 100%
	padding: 20px
	margin-bottom: 30px
	border: 1px solid #E0E6EC
	border-radius: 5px
	background: #fff
	transition: all .2s ease


	&:hover
		border-color: rgba($base-color, .7)

		&:nth-child(2n+1)
			&:before
				background: url($img-path + 'triangulo-noticia-hover.svg') no-repeat

		&:nth-child(2n+2)
			&:before
				background: url($img-path + 'triangulo-noticia2-hover.svg') no-repeat

		.sala-prensa__img
			img
				transform: scale(1.2)


	+min('l')
		width: calc(50% - 23px)

		&:before
			position: absolute
			content: ''
			width: 15px
			height: @width
			top: 6px
			transition: all .2s ease

		&:after
			position: absolute
			content: ''
			width: 22px
			height: @width
			top: 6px
			border: 5px solid #f5f8f8
			border-radius: 50%
			background: $base-color

	&:nth-child(2n+1)
		float: left
		clear: left


		&:before
			right: -9px
			background: url($img-path + 'triangulo-noticia.svg') no-repeat

		&:after
			right: -35px

	&:nth-child(2n+2)
		float: right

		+min('l')
			margin-top: 40px

		&:before
			left: -9px
			background: url($img-path + 'triangulo-noticia2.svg') no-repeat

		&:after
			left: -35px



.sala-prensa__box-detalle
	width: 100%
	padding: 20px
	margin-bottom: 30px
	border: 1px solid #E0E6EC
	border-radius: 5px
	background: #fff

	.block-content,
	.block-columnas
		+max('l')
			padding-left: 0
			padding-right: 0


.sala-prensa__titulo
	margin-bottom: 5px
	font-size: 2.1rem
	font-weight: $bold
	color: #484E53

.sala-prensa__titulo--detalle
	@extend .sala-prensa__titulo
	font-size: 2.6rem

.sala-prensa__fecha
	margin-bottom: 18px
	color: $base-color

	[class*="fa"]
		margin-right: 5px
		font-size: 1.6rem

.sala-prensa__img
	margin-bottom: 18px
	overflow: hidden

	img
		display: block
		transition: all .3s ease

.sala-prensa__p
	font-size: 1.5rem
	line-height: 2.4rem
	color: #7a858e


.sala-prensa__detalle
	+min('hd')
		max-width: 940px



.otras-noticias
	grid()

.title-section
	width: 100%
	margin-bottom: 30px
	text-align: center
	font-weight: $bold
	font-size: 2.2rem
	color: $base-color

.otras-noticias__item
	@extend .sala-prensa__item
	width: 100%
	position: static
	padding: 20px
	margin-bottom: 0
	border: 1px solid #E0E6EC
	border-radius: 5px
	background: #fff
	transition: all .2s ease

	+min('l')
		col(1,2)

	+max('l')
		margin-bottom: 20px

	.sala-prensa__img

		+min('m')
			float: left
			margin-right: 20px
			margin-bottom: 10px

	&:before,
	&:after
		display: none

	&:nth-child(2n+2)
		float: none
		margin-top: auto



.noticias__paginador
	position: relative
	width: 100%
	clear: both
	text-align: center
	z-index: 200

	[class*="btn"]
		margin: 5px 15px


.noticias__count
	margin-bottom: 10px

.noticias__total
	display: inline-block
	padding: 10px 20px
	border: 1px solid #e0e6ec
	border-radius: 5px
	background: #fff
	font-weight: $medium
