/******************************
*
*  RoyalSlider White Controls Skin
*
*    1. Arrows
*    2. Bullets
*    3. Thumbnails
*    4. Tabs
*    5. Fullscreen button
*    6. Play/close video button
*    7. Preloader
*    8. Caption
*
*  Sprite: 'rs-minimal-white.png'
*  Feel free to edit anything
*  If you don't some part - just delete it
*
******************************/


/* Background */
.rsMinW,
.rsMinW .rsOverflow,
.rsMinW .rsSlide,
.rsMinW .rsVideoFrameHolder,
.rsMinW .rsThumbs {
	/*background: #000;*/
	color: #FFF;
}


/***************
*
*  1. Arrows
*
****************/

.rsMinW .rsArrow {
	height: 32px;
	width: 32px;
	position: absolute;
	display: block;
	cursor: pointer;
	z-index: 21;
}

.rsMinW .rsArrowLeft { right: 37px; bottom: 7px; }
.rsMinW .rsArrowRight { right: 7px; bottom: 7px; }

.rsMinW .rsArrowIcn {
	width: 24px;
	height: 24px;
	margin-top: 3px;
	margin-left: 3px;
	position: absolute;
	cursor: pointer;
	background: url('../images/rs-minimal-white.png') transparent;

	border-radius: 2px;
}
.rsMinW .rsArrowIcn:hover {

}


.rsMinW.rsHor .rsArrowLeft .rsArrowIcn { background-position: -68px -36px; }
.rsMinW.rsHor .rsArrowRight .rsArrowIcn { background-position: -68px -68px; }

.rsMinW.rsVer .rsArrowLeft .rsArrowIcn { background-position: -100px -36px; }
.rsMinW.rsVer .rsArrowRight .rsArrowIcn { background-position: -100px -68px; }

.rsMinW .rsArrowDisabled .rsArrowIcn { opacity: .3 !important; filter: alpha(opacity=30);  *display: none; }


/***************
*
*  2. Bullets
*
****************/

.rsMinW .rsBullets {
	position: absolute;
	bottom: 35px;
	right: 0;
	left: 0;
	z-index: 35;
	padding-top: 4px;
	height: auto;

	text-align: center;
	line-height: 6px;
	overflow: hidden;
}
.rsMinW .rsBullet {
	width: 10px;
	height: 10px;
	display: inline-block;
	*display:inline;
	*zoom:1;
	padding: 6px 10px 15px 6px;
}
.rsMinW .rsBullet span {
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #CCC;
	background: rgba(255,255,255,0.5);
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.rsMinW .rsBullet.rsNavSelected span {
	background: #FFF;
}





/***************
*
*  3. Thumbnails
*
****************/

.rsMinW .rsThumbsHor {
	width: 100%;
	height: 72px;
}
.rsMinW .rsThumbsVer {
	width: 96px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
}
.rsMinW.rsWithThumbsHor .rsThumbsContainer {
	position: relative;
	height: 100%;
}
.rsMinW.rsWithThumbsVer .rsThumbsContainer {
	position: relative;
	width: 100%;
}
.rsMinW .rsThumb {
	float: left;
	overflow: hidden;
	width: 96px;
	height: 72px;
}
.rsMinW .rsThumb img {
	width: 100%;
	height: 100%;
}
.rsMinW .rsThumb.rsNavSelected {
	background: #333;
}
.rsMinW .rsThumb.rsNavSelected img {
	filter: alpha(opacity=40);
	opacity: 0.7;
}
.rsMinW .rsThumb.rsNavSelected span.thumbIco {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 2px solid #FFF;
	border: 2px solid rgba(255,255,255,0.9);
	-webkit-backface-visibility: hidden;
}
.rsMinW .rsTmb {
	display: block;
}




/* Thumbnails arrow icons */
.rsMinW .rsThumbsArrow {
	height: 100%;
	width: 20px;
	position: absolute;
	display: block;
	cursor: pointer;
	z-index: 21;
}
.rsMinW.rsWithThumbsVer .rsThumbsArrow {
	width: 100%;
	height: 20px;
}
.rsMinW.rsWithThumbsVer .rsThumbsArrowLeft { top: 0; left: 0; }
.rsMinW.rsWithThumbsVer .rsThumbsArrowRight { bottom: 0;  left: 0; }

.rsMinW.rsWithThumbsHor .rsThumbsArrowLeft { left: 0; top: 0; }
.rsMinW.rsWithThumbsHor .rsThumbsArrowRight { right: 0; top:0; }

.rsMinW .rsThumbsArrowIcn {
	width: 16px;
	height: 16px;
	top: 50%;
	left: 50%;
	margin-top:-8px;
	margin-left: -8px;
	position: absolute;
	cursor: pointer;
	background: url('../images/rs-minimal-white.png');
}

.rsMinW.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -128px -32px; }
.rsMinW.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -128px -48px; }

.rsMinW.rsWithThumbsVer .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -144px -32px; }
.rsMinW.rsWithThumbsVer .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -144px -48px; }

.rsMinW .rsThumbsArrowDisabled { display: none !important; }

/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
	.rsMinW .rsThumb {
		width: 59px;
		height: 44px;
	}
	.rsMinW .rsThumbsHor {
		height: 44px;
	}
	.rsMinW .rsThumbsVer {
		width: 59px;
	}
}




/***************
*
*  4. Tabs
*
****************/

.rsMinW .rsTabs {
	width: 100%;
	height: auto;
	margin: 0 auto;
	text-align:center;
	overflow: hidden; padding-top: 12px; position: relative;
}
.rsMinW .rsTab {
	display: inline-block;
	cursor: pointer;
	text-align: center;
	height: auto;
	width: auto;
	color: #333;
	padding: 5px 13px 6px;
	min-width: 72px;
	border: 1px solid #D9D9DD;
	border-right: 1px solid #f5f5f5;
	text-decoration: none;

	background-color: #000;

	*display:inline;
	*zoom:1;
}
.rsMinW .rsTab:first-child {
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.rsMinW .rsTab:last-child {
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;

	border-right:  1px solid #cfcfcf;
}
.rsMinW .rsTab:active {
	border: 1px solid #D9D9DD;
	background-color: #f4f4f4;
	box-shadow:  0 1px 4px rgba(0, 0, 0, 0.2) inset;
}
.rsMinW .rsTab.rsNavSelected {
	color: #000;
	border: 1px solid #999;
	text-shadow: 1px 1px #838383;
	box-shadow: 0 1px 9px rgba(102, 102, 102, 0.65) inset;
	background: #ACACAC;

}





/***************
*
*  5. Fullscreen button
*
****************/

.rsMinW .rsFullscreenBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 22;
	display: block;
	position: absolute;
	cursor: pointer;

}
.rsMinW .rsFullscreenIcn {
	display: block;
	margin: 6px;
	width: 32px;
	height: 32px;

	background: url('../images/rs-minimal-white.png') 0 0 transparent;
}
.rsMinW .rsFullscreenIcn:hover {
	opacity: 0.8;
}
.rsMinW.rsFullscreen .rsFullscreenIcn {
	background-position: -32px 0;
}





/***************
*
*  6. Play/close video button
*
****************/

.rsMinW .rsPlayBtn {
	-webkit-tap-highlight-color:rgba(0,0,0,0.3);
	width:64px;
	height:64px;
	margin-left:-32px;
	margin-top:-32px;
	cursor: pointer;
}
.rsMinW .rsPlayBtnIcon {
	width:64px;
	display:block;
	height:64px;
	-webkit-border-radius: 4px;
	border-radius: 4px;

	-webkit-transition: .3s;
	-moz-transition: .3s;
	transition: .3s;

	background:url(../images/rs-minimal-white.png) no-repeat 0 -32px;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
}
.rsMinW .rsPlayBtn:hover .rsPlayBtnIcon {
	background-color: rgba(0,0,0,0.9);
}
.rsMinW .rsBtnCenterer {
	position:absolute;
	left:50%;
	top:50%;
	width: 20px;
	height: 20px;
}
.rsMinW .rsCloseVideoBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 500;
	position: absolute;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);

}
.rsMinW .rsCloseVideoBtn.rsiOSBtn {
	top: -38px;
	right: -6px;
}

.rsMinW .rsCloseVideoIcn {
	margin: 6px;
	width: 32px;
	height: 32px;
	background: url('../images/rs-minimal-white.png') -64px 0;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
}
.rsMinW .rsCloseVideoIcn:hover {
	background-color: rgba(0,0,0,0.9);
}



/***************
*
*  7. Preloader
*
****************/

.rsMinW .rsPreloader {
	width:20px;
	height:20px;
	background-image:url(../images/preloader-white.gif);

	left:50%;
	top:50%;
	margin-left:-10px;
	margin-top:-10px;
}




/***************
*
*  8. Global caption
*
****************/
.rsMinW .rsGCaption {
	position: absolute;
	float: none;
	bottom: 6px;
	left: 6px;
	text-align: left;

	background: #000;
    background: rgba(0,0,0, 0.75);

	color: #FFF;
	padding: 2px 8px;
	width: auto;
	font-size: 12px;
	border-radius: 2px;
}
