/*----------------------------------------------------------------------------*
	$C-BLOCK
*----------------------------------------------------------------------------*/




/* ^.c-block-1
-----------------------------------------------------------------------------*/


.c-block-1
	position: fixed
	z-index: 200
	top: 0
	left: 0
	width: 100%
	border-bottom: 3px solid #e4eaee
	background: #fff


.c-block-1--scroll

	.c-block1__logo
		height: 100px

		img
			width: 180px

	// ul
	.c-nav-main
		> li
			> a
				height: 100px
				padding-top: 15px

				i
					margin-bottom: 10px
					font-size: 2rem

				span
					font-size: 1.6rem



.c-block1__logo
	display: flex
	align-items: center
	height: 130px
	transition: all .3s ease

	img
		width: 208px
		transition: all .3s ease

		+max('m')
			width: 180px

		+max('s')
			width: 160px


/**
 *
 * == .active-{color} ==
 * Se aplica de acuerdo al item activo en el menú
 *
 */
.active-verde
	border-bottom-color: darken($base3-color, 10)

.active-azul
	border-bottom-color: darken($base4-color, 10)

.active-naranja
	border-bottom-color: darken($base5-color, 10)

.active-verde2
	border-bottom-color: darken($base6-color, 10)

.active-morado
	border-bottom-color: darken($base7-color, 10)

.active-ocre
	border-bottom-color: darken($base8-color, 10)


/* ^Fin - .c-block-1
-----------------------------------------------------------------------------*/





/* ^.c-block-2
-----------------------------------------------------------------------------*/

.c-block-2
	position: relative
	z-index: 100
	padding-top: 60px
	padding-bottom: @padding-top
	background: #784198 url($img-path + 'bg-triangulos.jpg') center center fixed repeat

	+max('m')
		padding-top: 30px
		padding-bottom: @padding-top


.c-block-2__container
	align-items: center

	+min('xl')

		> *
			&:first-child
				padding-right: 20px

			&:last-child
				padding-left: 20px


.c-block-2__heading
	margin-bottom: 30px
	color: #fdf798

	+max('l')
		padding-top: 30px


.c-block-2__text
	margin-bottom: 30px
	text-align: justify
	font-size: 1.7rem
	line-height: 1.5
	color: #fff




/* ^Fin - .c-block-2
-----------------------------------------------------------------------------*/





/* ^.c-block-3
-----------------------------------------------------------------------------*/

.c-block-3
	padding-top: 55px
	padding-bottom: 134px

.c-block-3--bg-circulos
	@extend .c-block-3
	background: url($img-path + 'bg-circulos.png') center top no-repeat
	background-size: 1300px auto

/* ^Fin - .c-block-3
-----------------------------------------------------------------------------*/





/* ^.c-block-5
-----------------------------------------------------------------------------*/

.c-block-5
	position: relative
	z-index: 100
	padding-top: 40px
	padding-bottom: 50px
	background: $base-color url($img-path + 'bg-triangulos2.jpg') center center fixed repeat

	+max('m')
		padding-top: 30px
		padding-bottom: 50px

	&:before
		content: ''
		position: absolute
		bottom: 100%
		width: 100%
		height: 118px
		background: url($img-path + 'bg-campo.png') center bottom no-repeat

		+max('m')
			background-position: -800px bottom

		+max(400px)
			background-position: -870px bottom

		+max('s')
			background-position: -925px bottom


.c-block-5__container
	flex-direction: column
	align-items: center


// h3
.c-block-5__heading
	margin-bottom: 15px
	font-weight: $normal
	color: #fff


.c-block-5__copy
	margin-bottom: 40px
	font-size: 1.7rem
	text-align: center
	color: #fff


// a
.c-block-5__nigma
	img
		width: 25px

	span
		display: none


/* ^Fin - .c-block-5
-----------------------------------------------------------------------------*/





/* ^.c-block-6
-----------------------------------------------------------------------------*/

.c-block-6
	position: relative
	z-index: 5
	background: #E5E5E5 url($img-path + 'bg-c-block-6.svg') 0 200px repeat-y
	background-size: 100% auto

	+max('xl')
		display: none




/* ^Fin - .c-block-6
-----------------------------------------------------------------------------*/





/* ^.c-block-7__header
-----------------------------------------------------------------------------*/

.c-block-7__content
	padding: 50px 0

	+min('xl')
		padding: 50px 0 50px 25px


.c-block-7__content-2
	padding: 40px 0


/* ^Fin - .c-block-7__header
-----------------------------------------------------------------------------*/
