/*----------------------------------------------------------------------------*
	$SLIDER
*----------------------------------------------------------------------------*/


/* ^.c-slider-hero
-----------------------------------------------------------------------------*/

.c-slider-hero
	position: relative
	width: 100% !important
	max-height: 560px !important
	background: #29bb90
	overflow: hidden


.bloque-iz,
.bloque-der
	position: absolute
	width: 1px
	height: 10px
	white-space: normal !important

.bloque-iz
	.block-caption

		+min('m')
			left: -192px

		+min('xl')
			left: -464px

		+min('hd')
			left: -567px

.bloque-der
	.block-caption

		+min('m')
			left: -192px

		+min('xl')
			left: 80px

		+min('hd')
			left: 183px

.block-caption
	position: absolute
	width: 384px
	height: 300px
	padding: 40px 20px 20px 20px
	bottom: 0
	background: rgba(#ffff,.9)

	+max('l')
		padding: 20px
		height: auto
		min-height: 100px
		bottom: 100px
		border-radius: 5px
		text-align: center

	+max('m')
		display: none

// h2
.caption__titulo
	margin-bottom: 25px
	font-size: 2.5rem
	color: #2e9670

	+max('l')
		font-size: 1.9rem

// p
.caption__texto
	line-height: 1.4
	color: #687076

	+max('l')
		display: none

/* ^Fin - .c-slider-hero
-----------------------------------------------------------------------------*/
